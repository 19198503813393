<template>
	<form class="main-wrapper mt-4">
		<CRow class="mb-5">
			<CCol md="12">
				<h4>General info</h4>
				<CInput
					v-model.trim="$v.localName.$model"
					:is-valid="!$v.localName.$error && null"
					:invalid-feedback="$t('global.error.required')"
					class="mt-4"
					label="Installment plan name (for back office)*"
					placeholder="E.g. Apple KTC installment plan"
				/>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>
			<CCol md="12">
				<h4>Supplier ID</h4>
				<div class="typo-body-2 color-black-45">
					Set supplier ID for this installment plan to let supplier to absorb the interest
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<CInput
						v-model.trim="$v.localSupplierId.$model"
						:is-valid="!$v.localSupplierId.$error && null"
						invalid-feedback="Must have 4 digits"
						label="Supplier ID"
						placeholder="E.g. 1234"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>

			<!-- Edit -->
			<CCol v-if="isEdit" md="12">
				<CRow>
					<!-- Available SKUs -->
					<CCol md="12">
						<CRow>
							<CCol md="12">
								<h4>Available SKUs</h4>
								<div class="typo-body-2 color-black-45">
									Set available SKUs for the installment plans above by specifying SKU or brand. Can't add SKU or brand that have already been used in other plans.
								</div>
								<div class="form-group mt-3 form-group-available-sku">
									<div class="label">Product qty </div>
									<div class="value">
										{{ availableSkus | numberFormat }} SKU(s)
									</div>
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group mt-3 form-group-file">
									<CButton
										:disabled="isImporting || isExporting"
										class="btn-import"
										color="secondary"
										@click="importFile"
									>
										{{ isImporting ? "Importing" : "Import .xlsx" }}
									</CButton>

									<CButton
										:disabled="isImporting || isExporting"
										class="btn-export"
										color="secondary"
										@click="handleExportFile"
									>
										{{ isExporting ? "Exporting" : "Export .xlsx" }}
									</CButton>
								</div>
							</CCol>
						</CRow>
					</CCol>

					<CCol md="12">
						<hr class="mb-4">
					</CCol>
					<!-- End Available SKUs -->
				</CRow>
			</CCol>

			<!-- Create -->
			<CCol md="12">
				<h4>Plan info</h4>
				<div class="typo-body-2 color-black-45">
					Currently, we offer customers up to 36-months installment plan (terms depend on the chosen bank). e.g. KTC fixed at 3, 6 or 10 months with monthly interest at 0.8%
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localInterest.$model"
						:is-valid="!$v.localInterest.$error"
						:invalid-feedback="$t('global.error.required')"
						:decimal-scale="2"
						label="Interest rate*"
						append-text="%"
					/>
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localPeriod.$model"
						:is-valid="!$v.localPeriod.$error"
						invalid-feedback="Months must be between 1 and 99"
						class="input-period"
						label="Payment term*"
						append-text="Month"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>
			<CCol md="12">
				<h4>Order value</h4>
				<div class="typo-body-2 color-black-45">
					Set min-max of the purchase order value for this plan, if any.
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="minOrder"
						v-model="localMinOrder"
						:decimal-scale="2"
						label="Minimum order"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
						allow-null-value
					/>
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="maxOrder"
						v-model="localMaxOrder"
						:decimal-scale="2"
						:is-valid="!$v.localMaxOrder.$error"
						:invalid-feedback="maxOrderErrorMessage"
						label="Maximum order"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
						allow-null-value
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>
			<CCol md="12">
				<h2 class="typo-h4">
					Active dates
				</h2>
			</CCol>
			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.localStartDate.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.localStartDate.$error"
					:min-date="minActiveDate"
					label="Start date*"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.localStartTime.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.localStartTime.$error"
					:invalid-feedback="errorStartTime"
					label="Start time"
				/>
			</CCol>
			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.localEndDate.$model"
					:disabled="!isEnableEndActiveDate || $v.localStartDate.$invalid"
					:is-valid="!$v.localEndDate.$error"
					:min-date="localStartDate"
					label="End date*"
					invalid-feedback="End date is required"
					placeholder="Select date"
					data-test-id="end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.localEndTime.$model"
					:disabled="!isEnableEndActiveDate"
					:is-valid="!$v.localEndTime.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
				/>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
			<!-- End Active -->
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="!isEnableEndActiveDate || isSubmitting"
			:remove-text="isEdit ? 'Remove plan' : null"
			:is-edit="isEdit"
			content-class="main-wrapper"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToPaymentSetting"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteInstallmentPlan.bind(null, { methodId: methodId, planId: this.$route.params.planId })"
			title="Remove this installment plan?"
			description="By removing this, the installment plan and condition will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToPaymentSetting"
		/>
	</form>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';

import isEmpty from 'lodash/isEmpty';
import { required, minValue, maxValue, maxLength } from 'vuelidate/lib/validators';
import { priceToAPI, numberDecimalFormat, convertDateTimeToUTC } from '../assets/js/helpers';

import { ROUTE_NAME } from '../enums/route';

import { exportInstallmentPlanSkuAPI } from '../services/api/export.api';
import { importInstallmentPlanAPI } from '../services/api/import.api';
import { afterDateTime, timeFormat } from '../assets/js/validators';
import { INSTALLMENT_PLAN_PERIODS_STATUSES } from '../enums/ktcInstallmentPlans';

export default {
	name: 'FormBrandInstallmentPlan',

	validations: {
		localName: {
			required,
		},
		localInterest: {
			required,
		},
		localPeriod: {
			required,
			minValue: minValue(1),
			maxValue: maxValue(99),
		},
		localMaxOrder: {
			greaterThanMinOrder: (_, { localMinOrder, localMaxOrder }) => {
				if (!isEmpty(localMinOrder) && !isEmpty(localMaxOrder)) {
					return Number(localMaxOrder) > Number(localMinOrder);
				}

				return true;
			},
		},
		localSupplierId: {
			maxLength: maxLength(4),
		},
		localStartDate: {
			required,
		},
		localEndDate: {
			required,
		},
		localStartTime: {
			required,
			timeFormat,
		},
		localEndTime: {
			required,
			timeFormat,
			after: afterDateTime({
				fromDateKey: 'localStartDate',
				fromTimeKey: 'localStartTime',
				toDateKey: 'localEndDate',
				toTimeKey: 'localEndTime',
			}),
		},
	},
	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		methodId: {
			type: [String, Number],
			default: null,
		},
		groupId: {
			type: [String, Number],
			default: null,
		},
		planId: {
			type: [String, Number],
			default: null,
		},
		interest: {
			type: Number,
			default: null,
		},
		period: {
			type: Number,
			default: null,
		},
		minOrderAmount: {
			type: String,
			default: null,
		},
		maxOrderAmount: {
			type: String,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		gateway: {
			type: String,
			default: null,
		},
		customAttributes: {
			type: Object,
			default: () => ({}),
		},
		availableSkus: {
			type: Number,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		periodStatus: {
			type: String,
			default: null,
		},
		startDate: {
			type: Date,
			default: null,
		},
		endDate: {
			type: Date,
			default: null,
		},
		startTime: {
			type: String,
			default: null,
		},
		endTime: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			// export
			isExporting: false,
			// import
			totalSku: null,
			isImporting: false,

			// form
			localName: null,
			localPeriod: null,
			localInterest: null,
			localSupplierId: null,
			localMinOrder: null,
			localMaxOrder: null,
			minActiveDate: new Date(),
			localStartDate: null,
			localEndDate: null,
			localStartTime: '00:00',
			localEndTime: '23:59',
			localPeriodStatus: null,
		};
	},
	computed: {
		maxOrderErrorMessage() {
			if (!this.$v.localMaxOrder.greaterThanMinOrder) {
				return `Maximum order should be more than ${numberDecimalFormat(this.localMinOrder)}`;
			}

			return null;
		},
		errorStartTime() {
			if (!this.$v.localStartTime.required) {
				return this.$t('global.error.required');
			}
			if (!this.$v.localStartTime.validTime) {
				return 'Time not valid';
			}
			return null;
		},
		errorEndTime() {
			if (!this.$v.localEndTime.required) {
				return this.$t('global.error.required');
			}
			if (!this.$v.localEndTime.timeFormat) {
				return 'Time not valid';
			}
			if (!this.$v.localEndTime.after) {
				return this.$t('global.error.timeAfter', { time: this.localStartTime });
			}
			return null;
		},
		isEnableStartActiveDate() {
			return ![INSTALLMENT_PLAN_PERIODS_STATUSES.ON_GOING, INSTALLMENT_PLAN_PERIODS_STATUSES.EXPIRED].includes(this.localPeriodStatus);
		},
		isEnableEndActiveDate() {
			return ![INSTALLMENT_PLAN_PERIODS_STATUSES.EXPIRED].includes(this.localPeriodStatus);
		},
	},
	mounted() {
		if (this.isEdit) {
			this.localName = this.name;
			this.localInterest = numberDecimalFormat(this.interest, 2);
			this.localPeriod = this.period;
			this.localMinOrder = this.minOrderAmount;
			this.localMaxOrder = this.maxOrderAmount;
			this.localStartDate = this.startDate;
			this.localEndDate = this.endDate;
			this.localStartTime = this.startTime;
			this.localEndTime = this.endTime;
			this.localPeriodStatus = this.periodStatus;

			// Custom attributes
			this.localSupplierId = this.customAttributes.supplierId;
			this.localAbsorptionType = this.customAttributes.absorptionType;
		}
	},
	methods: {
		...mapActions({
			deleteInstallmentPlan: 'payments/deleteInstallmentPlan',
			showToast: 'toast/showToast',
		}),

		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const params = {
				name: this.localName,
				interest_rate: this.localInterest,
				period: this.localPeriod,
				started_at: convertDateTimeToUTC(this.localStartDate, this.localStartTime),
				ended_at: convertDateTimeToUTC(this.localEndDate, this.localEndTime),
				min_order_amount: priceToAPI(this.localMinOrder),
				max_order_amount: priceToAPI(this.localMaxOrder),
				custom_attributes: {
					supplier_id: this.localSupplierId,
					absorption_type: 'merchant_supplier',
				},
			};

			this.$emit('onSubmit', params);
		},
		redirectToPaymentSetting() {
			this.$router.push({ name: ROUTE_NAME.KTC_INSTALLMENT_PLAN_LIST });
		},
		handleRemovePlan() {
			this.redirectToPaymentSetting();
		},

		async handleExportFile() {
			this.isExporting = true;

			const { planId } = this.$route.params;
			try {
				await exportInstallmentPlanSkuAPI(planId);

				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
			}
		},

		importFile() {
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.setAttribute('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel');
			fileSelector.onchange = async () => {
				this.isImporting = true;
				const file = fileSelector.files.item(0);
				const { planId } = this.$route.params;

				try {
					await importInstallmentPlanAPI(file, planId);

					// 5 - 30 min to w8 something done
					this.showToast({
						header: this.$t('global.successMessageTitle'),
						content: `Your request has been completed.`,
						type: 'success',
					});
				} catch (e) {
					this.showToast({
						content: 'Installment plan upload failed',
						header: 'Failure',
						type: 'danger',
					});
				} finally {
					this.isImporting = false;
				}
			};
			fileSelector.click();
		},
		handleActiveStartDateChange(value) {
			if (!this.localEndDate || dayjs(value).isAfter(dayjs(this.localEndDate))) {
				this.localEndDate = value;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-period {
		.form-control-number {
			// override padding left append text
			padding-right: rem(60) !important;
		}
	}

	.icon-edit-plan {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}

	.form-group-file {
		display: flex;
		gap: rem(16);
	}

	.form-group-available-sku {
		display: flex;
		margin-bottom: 0;
		gap: rem(8);

		> .label {
			font-weight: 400;
			font-size: rem(14);
			margin-bottom: rem(8);
		}

		> .value {
			font-weight: 400;
			font-size: rem(14);
		}
	}
</style>
